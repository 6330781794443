import React from "react"
import { Router } from "@reach/router"
import ProductPage from "../templates/ProductPage"
import { Helmet } from "react-helmet"

export default function Products()
{
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="robots" content="noindex"/>
        </Helmet>
        <Router>
            <ProductPage  path="/products/:id" />
        </Router>
        </>
    )
}
