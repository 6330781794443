import React from 'react';

import styled from "styled-components";
import {Link} from "gatsby";


const Wrapper = styled.div`
  padding: 40px 10px;
  .button{
    padding-bottom: 5px;
    font-weight: 700;
    transition: .4s;
    &:hover{
      border-bottom: 1px solid #000;
    }
  }
`

export default function ProductUndefined(){
    return (
        <Wrapper className={"container"}>
            <h1>Unfortunately, this product is sold.</h1>
            <p>return to
                <Link to={"/menu"} className={'button'}> product list</Link>
            </p>
        </Wrapper>
    )
}